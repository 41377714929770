import React from 'react';

const SixMBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="39.859" height="27.531" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-183 -1780)">
      <g transform="translate(-628 1509)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(198.07 1801.235)">
        <g transform="translate(0 0)" clipPath="url(#clip-path)">
          <path
            d="M14.938,18.8a11.315,11.315,0,0,1-.548,3.63,8.3,8.3,0,0,1-1.513,2.761,6.5,6.5,0,0,1-2.307,1.74,7.059,7.059,0,0,1-2.931.605,7.852,7.852,0,0,1-3.063-.586,6.335,6.335,0,0,1-2.42-1.8,8.545,8.545,0,0,1-1.588-3.12A15.962,15.962,0,0,1,0,17.51a23.677,23.677,0,0,1,.927-6.9,17.865,17.865,0,0,1,2.5-5.238A13.847,13.847,0,0,1,7.015,1.834,12.538,12.538,0,0,1,11.27,0L12.4,3.668A9.383,9.383,0,0,0,9.63,4.879a10.956,10.956,0,0,0-2.318,2A11.373,11.373,0,0,0,5.583,9.549a14.024,14.024,0,0,0-1.045,3.158A5.08,5.08,0,0,1,6.17,11.232,4.806,4.806,0,0,1,8.6,10.664a6.4,6.4,0,0,1,2.524.492,5.315,5.315,0,0,1,2.012,1.513,7.3,7.3,0,0,1,1.328,2.553,12.114,12.114,0,0,1,.474,3.574m-4.311.265a5.618,5.618,0,0,0-.785-3.347,2.806,2.806,0,0,0-2.392-1.04,3.029,3.029,0,0,0-1.952.643A3.649,3.649,0,0,0,4.35,16.829q-.039.417-.038.851v.851A8.843,8.843,0,0,0,4.5,20.4,5.472,5.472,0,0,0,5.1,21.991a3.4,3.4,0,0,0,1,1.116,2.432,2.432,0,0,0,1.435.416,2.67,2.67,0,0,0,2.239-1.153,5.454,5.454,0,0,0,.862-3.309"
            transform="translate(0 0)"
            fill="currentColor"
          />
          <path
            d="M75.552,14.53l.529-5.067h-.189L74.493,14l-4.311,9.3H68.594l-4.576-9.341-1.4-4.576h-.227l.719,5.105v13.69h-4.5V1.71h4.576l5.521,11.459.908,3.29h.151l.87-3.366L75.817,1.71h4.5V28.182H75.552Z"
            transform="translate(-40.458 -1.18)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SixMBorder;
